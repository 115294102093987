import { Injectable } from '@angular/core';
import { IncidenceModel } from '../models/incidence.model';
import { HttpClient } from '@angular/common/http';
import { ConfigApi } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class IncidenceService {

    private URL_API: string;

    constructor(private http: HttpClient, private config: ConfigApi) {
        this.URL_API = config.URL_SERVICES + '/incidence/';
    }

    getIncidenceId(id: number) {
        return this.http.get(this.URL_API + id, this.config.OPTIONS_HTTP);
    }

    getIncidenceByCustomerId(id: number) {
        return this.http.get(this.URL_API + 'customer/' + id, this.config.OPTIONS_HTTP);
    }

    getIncidences() {
        return this.http.get(this.URL_API, this.config.OPTIONS_HTTP);
    }

    add(incidenceModel: IncidenceModel) {
        return this.http.post(this.URL_API, incidenceModel, this.config.OPTIONS_HTTP);
    }

    update(incidenceModel: IncidenceModel, user_id: number) {
        return this.http.put(this.URL_API + 'update/' + incidenceModel.id, {incidence: incidenceModel, user_id}, this.config.OPTIONS_HTTP);
    }

    updateState(incidenceModel: IncidenceModel, state: any) {
        return this.http.put(this.URL_API + 'state/' + incidenceModel.id, state, this.config.OPTIONS_HTTP);
    }

    updateOperator(incidenceModel: IncidenceModel, user_assign_id: any) {
        return this.http.put(this.URL_API + 'operator/' + incidenceModel.id, user_assign_id, this.config.OPTIONS_HTTP);
    }
}
