export class CustomerModel {

    constructor(
        public id: number = null,
        public business_name: string = null,
        public nif: string = null,
        public acronym: string = null,
        public type_road_id: number = null,
        public direction: string = null,
        public city: string = null,
        public cp: string = null,
        public province_id: number = null,
        public contact_person: string = null,
        public contact_phone: string = null,
        public contact_mobile: string = null,
        public contact_email: string = null,
        public attorney: string = null,
        public nif_attorney: string = null,
        public iban: string = null,
        public comercial_id: number = null,
        public type_customer: number = null,
        public observations: string = null,
        public unsubscribe_date: string = null,
        public repeated_incidences: number = 0,
        public relevance: number = 0,
        public technic_person: string = null,
        public technic_email: string = null,
        public technic_phone: string = null
    ) { }

}
