import { Injectable } from '@angular/core';
import { ProductModel } from '../models/product.model';
import { HttpClient } from '@angular/common/http';
import { ConfigApi } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    private URL_API: string;
    constructor(private http: HttpClient, private config: ConfigApi) {
        this.URL_API = config.URL_SERVICES + '/product/';
    }

    add(product: ProductModel) {
        return this.http.post(this.URL_API, product, this.config.OPTIONS_HTTP);
    }

    getProducts() {
        return this.http.get(this.URL_API, this.config.OPTIONS_HTTP);
    }

    getOptions() {
        return this.http.get(this.URL_API + 'option_list', this.config.OPTIONS_HTTP);
    }


    update(product: ProductModel) {
        return this.http.put(this.URL_API + product.id, product, this.config.OPTIONS_HTTP);
    }

    getProductId(id: number) {
        return this.http.get(this.URL_API + id, this.config.OPTIONS_HTTP);
    }

    delete(id: number) {
        return this.http.delete(this.URL_API + id, this.config.OPTIONS_HTTP);
    }


}
