import { Injectable } from '@angular/core';
import { DiscountModel } from '../models/discount.model';
import { HttpClient } from '@angular/common/http';
import { ConfigApi } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class DiscountService {
    private URL_API: string;
    constructor(private http: HttpClient, private config: ConfigApi) {
        this.URL_API = config.URL_SERVICES + '/discount/';
    }

    add(discount: DiscountModel) {
        return this.http.post(this.URL_API, discount, this.config.OPTIONS_HTTP);
    }

    getDiscounts() {
        return this.http.get(this.URL_API, this.config.OPTIONS_HTTP);
    }

    getUsers() {
        return this.http.get(this.URL_API + 'users' , this.config.OPTIONS_HTTP);
    }
    
    getUserById(user:any) {
        return this.http.get(this.URL_API + `user/${user}` , this.config.OPTIONS_HTTP);
    }


    update(discount: DiscountModel) {
        return this.http.put(this.URL_API + discount.id, discount, this.config.OPTIONS_HTTP);
    }

    getDiscountId(id: number) {
        return this.http.get(this.URL_API + id, this.config.OPTIONS_HTTP);
    }

    delete(id: number) {
        return this.http.delete(this.URL_API + id, this.config.OPTIONS_HTTP);
    }


}
