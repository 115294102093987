import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigApi } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class InstallationService {

    private URL_API: string;

    constructor(private http: HttpClient, private config: ConfigApi) {
        this.URL_API = config.URL_SERVICES + '/installation/';
    }

    getInstallations() {
        return this.http.get(this.URL_API, this.config.OPTIONS_HTTP);
    }

    getInstallationId(id: number) {
        return this.http.get(this.URL_API + id, this.config.OPTIONS_HTTP);
    }

    add(data: any) {
        return this.http.post(this.URL_API, data, this.config.OPTIONS_HTTP);
    }

    update(data: any) {
        return this.http.put(this.URL_API + data.installation.id, data, this.config.OPTIONS_HTTP);
    }
}
