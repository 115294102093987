import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigApi } from '../config/config';
import { ToDoModel } from '../models/todo.model';
import { OpportunityModel } from '../models/opportunity.model';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private URL_API: string;

    constructor(private http: HttpClient, private config: ConfigApi) {
        this.URL_API = config.URL_SERVICES + '/dashboard/';
    }

    getToDos(n: number) {
        return this.http.get(this.URL_API + 'todos/' + n, this.config.OPTIONS_HTTP);
    }

    setToDoComplete(id: number) {
        return this.http.get(this.URL_API + 'todocomplete/' + id, this.config.OPTIONS_HTTP);
    }

}

