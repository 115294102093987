import { Injectable } from '@angular/core';
import { RolModel } from '../models/rol.model';

@Injectable({
    providedIn: 'root'
})
export class RolService {

    private values: RolModel[];

    constructor() {
        this.values = [
            new RolModel('Administrador', 1),
            new RolModel('Comercial', 2),
            new RolModel('Canal Indirecto', 3),
            new RolModel('Técnico', 4),
            new RolModel('Backoffice', 5)
        ];
    }

    public getRols() {
        return this.values;
    }
}
